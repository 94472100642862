<template>
    <v-container fluid fill-height class="ma-0 pa-0">
        <ag-table :columns="columns" :rows="units" :loading="loading" link="unit" />
    </v-container>
</template>

<script>
import axios from 'axios';
import AgTable from '@/components/AgTable.vue';

export default {
    name: 'UnitList',

    components: {
        AgTable
    },

    data() {
        return {
            loading: true,
            units: [],
            columns: [
                { field: 'id', headerName: 'ID', sortable: true, resizable: true, width: 90 },
                { field: 'aemoId', headerName: 'DUID', sortable: true, resizable: true, width: 120, filter: true },
                { field: 'station.name', headerName: 'Station', sortable: true, resizable: true, width: 220, filter: true },
                { field: 'fuelType.name', headerName: 'Fuel Type', sortable: true, resizable: true, filter: true },
                { field: 'generationTechnology.name', headerName: 'Gen Tech', sortable: true, resizable: true, filter: true },
                { field: 'aemoClass.name', headerName: 'AEMO Class', sortable: true, resizable: true, filter: true },
                { field: 'oem.name', headerName: 'Turbine Supplier', sortable: true, resizable: true, filter: true },
                { field: 'participantType.name', headerName: 'Participant Type', sortable: true, resizable: true, filter: true },
                { field: 'physUnitNo', headerName: 'Physical Units', sortable: true, resizable: true }
            ]
        };
    },

    async created() {
        this.loading = true;
        const response = await axios.get('/api/units');
        this.units = response.data;
        this.loading = false;
    }
};
</script>
